@font-face {
	font-family: 'Inter';
	font-display: swap;
	font-style:  normal;
	font-weight: 100;
	src: url("/assets/frappe/css/fonts/inter/inter_thin.woff2") format("woff2"),
			url("/assets/frappe/css/fonts/inter/inter_thin.woff") format("woff");
}
@font-face {
	font-family: 'Inter';
	font-display: swap;
	font-style:  italic;
	font-weight: 100;
	src: url("/assets/frappe/css/fonts/inter/inter_thinitalic.woff2") format("woff2"),
			url("/assets/frappe/css/fonts/inter/inter_thinitalic.woff") format("woff");
}

@font-face {
	font-family: 'Inter';
	font-display: swap;
	font-style:  normal;
	font-weight: 200;
	src: url("/assets/frappe/css/fonts/inter/inter_extralight.woff2") format("woff2"),
			url("/assets/frappe/css/fonts/inter/inter_extralight.woff") format("woff");
}
@font-face {
	font-family: 'Inter';
	font-display: swap;
	font-style:  italic;
	font-weight: 200;
	src: url("/assets/frappe/css/fonts/inter/inter_extralightitalic.woff2") format("woff2"),
			url("/assets/frappe/css/fonts/inter/inter_extralightitalic.woff") format("woff");
}

@font-face {
	font-family: 'Inter';
	font-display: swap;
	font-style:  normal;
	font-weight: 300;
	src: url("/assets/frappe/css/fonts/inter/inter_light.woff2") format("woff2"),
			url("/assets/frappe/css/fonts/inter/inter_light.woff") format("woff");
}
@font-face {
	font-family: 'Inter';
	font-display: swap;
	font-style:  italic;
	font-weight: 300;
	src: url("/assets/frappe/css/fonts/inter/inter_lightitalic.woff2") format("woff2"),
			url("/assets/frappe/css/fonts/inter/inter_lightitalic.woff") format("woff");
}

@font-face {
	font-family: 'Inter';
	font-display: swap;
	font-style:  normal;
	font-weight: 400;
	src: url("/assets/frappe/css/fonts/inter/inter_regular.woff2") format("woff2"),
			url("/assets/frappe/css/fonts/inter/inter_regular.woff") format("woff");
}
@font-face {
	font-family: 'Inter';
	font-display: swap;
	font-style:  italic;
	font-weight: 400;
	src: url("/assets/frappe/css/fonts/inter/inter_italic.woff2") format("woff2"),
			url("/assets/frappe/css/fonts/inter/inter_italic.woff") format("woff");
}

@font-face {
	font-family: 'Inter';
	font-display: swap;
	font-style:  normal;
	font-weight: 500;
	src: url("/assets/frappe/css/fonts/inter/inter_medium.woff2") format("woff2"),
			url("/assets/frappe/css/fonts/inter/inter_medium.woff") format("woff");
}
@font-face {
	font-family: 'Inter';
	font-display: swap;
	font-style:  italic;
	font-weight: 500;
	src: url("/assets/frappe/css/fonts/inter/inter_mediumitalic.woff2") format("woff2"),
			url("/assets/frappe/css/fonts/inter/inter_mediumitalic.woff") format("woff");
}

@font-face {
	font-family: 'Inter';
	font-display: swap;
	font-style:  normal;
	font-weight: 600;
	src: url("/assets/frappe/css/fonts/inter/inter_semibold.woff2") format("woff2"),
			url("/assets/frappe/css/fonts/inter/inter_semibold.woff") format("woff");
}
@font-face {
	font-family: 'Inter';
	font-display: swap;
	font-style:  italic;
	font-weight: 600;
	src: url("/assets/frappe/css/fonts/inter/inter_semibolditalic.woff2") format("woff2"),
			url("/assets/frappe/css/fonts/inter/inter_semibolditalic.woff") format("woff");
}

@font-face {
	font-family: 'Inter';
	font-display: swap;
	font-style:  normal;
	font-weight: 700;
	src: url("/assets/frappe/css/fonts/inter/inter_bold.woff2") format("woff2"),
			url("/assets/frappe/css/fonts/inter/inter_bold.woff") format("woff");
}
@font-face {
	font-family: 'Inter';
	font-display: swap;
	font-style:  italic;
	font-weight: 700;
	src: url("/assets/frappe/css/fonts/inter/inter_bolditalic.woff2") format("woff2"),
			url("/assets/frappe/css/fonts/inter/inter_bolditalic.woff") format("woff");
}

@font-face {
	font-family: 'Inter';
	font-display: swap;
	font-style:  normal;
	font-weight: 800;
	src: url("/assets/frappe/css/fonts/inter/inter_extrabold.woff2") format("woff2"),
			url("/assets/frappe/css/fonts/inter/inter_extrabold.woff") format("woff");
}
@font-face {
	font-family: 'Inter';
	font-display: swap;
	font-style:  italic;
	font-weight: 800;
	src: url("/assets/frappe/css/fonts/inter/inter_extrabolditalic.woff2") format("woff2"),
			url("/assets/frappe/css/fonts/inter/inter_extrabolditalic.woff") format("woff");
}

@font-face {
	font-family: 'Inter';
	font-display: swap;
	font-style:  normal;
	font-weight: 900;
	src: url("/assets/frappe/css/fonts/inter/inter_black.woff2") format("woff2"),
			url("/assets/frappe/css/fonts/inter/inter_black.woff") format("woff");
}
@font-face {
	font-family: 'Inter';
	font-display: swap;
	font-style:  italic;
	font-weight: 900;
	src: url("/assets/frappe/css/fonts/inter/inter_blackitalic.woff2") format("woff2"),
			url("/assets/frappe/css/fonts/inter/inter_blackitalic.woff") format("woff");
}
